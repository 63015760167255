import {getAccessToken} from "../../auth/verifyAuth"

async function getUser() {
    var accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/user", {
        method: "GET",
        headers : {
            "my-Authorization": `Bearer ${accessToken}`
        }
    })
}

export {
    getUser
}